import React from 'react'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl-v6'
import appleIcon from '../static/images/icon-apple.png'
import { apkDownloadUrl } from '../utils/url'

export default AbWallet

function AbWallet() {
  let pageModel = new PageModelTitle('AB Wallet', 'abwallet-html')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}

function Main() {
  const intl = useIntl()
  return (
    <div id={'abwallet'}>
      <div className='container'>
        <div className={'abwallet-content'}>
          <ul>
            <li className={'wallet-desc'}>
              <h1>AB <span>{ intl.formatMessage({ id: 'Wallet' }) }</span></h1>
              <h3>{ intl.formatMessage({ id: 'Decentralized Digital Wallet Of' }) }</h3>
              <h3>{ intl.formatMessage({ id: 'Newton Community Economy' }) }</h3>
              <div className={'building'}>
                <a href="https://itunes.apple.com/app/newpay/id1439660801">
                  <img src={appleIcon} className='apple-icon' />
                  <span>{ intl.formatMessage({ id: 'Download on the' }) }<br /><b>App Store</b></span>
                </a>
                <a href={ apkDownloadUrl }>
                  <b>{ intl.formatMessage({ id: 'Download' }) }APK</b>
                </a>
              </div>
            </li>
            <li>
              <div className='img-box'>
                <StaticImage
                  placeholder="blurred"
                  className={'stake-img'}
                  alt="img"
                  src="../static/images/abwallet/wallet.png"
                />
              </div>
            </li>
          </ul>
        </div>
        <div className={'abwallet-content'}>
          <ul>
            <li>
              <div className='img-box'>
                <StaticImage
                  placeholder="blurred"
                  className={'stake-img'}
                  alt="img"
                  src="../static/images/abwallet/digit.png"
                />
              </div>
            </li>
            <li className={'wallet-desc'}>
              <h1>{ intl.formatMessage(
                { id: 'Digital Wallet.text' }, 
                { Wallet: <span>{intl.formatMessage({ id: 'Digital Wallet.Wallet'}
              )}</span> }) }</h1>
              <h3>{ intl.formatMessage({ id: 'Multiple Tokens Support.' }) }</h3>
              <h3>{ intl.formatMessage({ id: 'Fast & Secure.' }) }</h3>
            </li>
          </ul>
        </div>
      </div>
      <CentenMobile />
    </div>
  )
}

const CentenMobile = () => {
  const intl = useIntl()
  return (
    <div className='container'>
    <div className={'abwallet-h5'}>
      <ul>
        <li className={'wallet-desc'}>
          <h1>AB <span>{ intl.formatMessage({ id: 'Wallet' }) }</span></h1>
          <h3>{ intl.formatMessage({ id: 'Decentralized Digital Wallet Of Newton Community Economy.' }) }</h3>
          {/* <h3>{ intl.formatMessage({ id: 'Newton Community Economy' }) }</h3> */}
        </li>
        <li>
          <div className='img-box'>
            <StaticImage
              placeholder="blurred"
              className={'stake-img'}
              alt="img"
              src="../static/images/abwallet/wallet.png"
            />
          </div>
        </li>
          <div className={'building'}>
            <a href="https://itunes.apple.com/app/newpay/id1439660801">
              <img src={appleIcon} className='apple-icon' />
              <span>{ intl.formatMessage({ id: 'Download on the' }) }<br /><b>App Store</b></span>
            </a>
            <a href={ apkDownloadUrl }>
              <b>{ intl.formatMessage({ id: 'Download' }) } APK</b>
            </a>
          </div>
      </ul>
    </div>
    <div className={'abwallet-h5'}>
      <ul>
        <li className={'wallet-desc'}>
          <h1>{ intl.formatMessage(
              { id: 'Digital Wallet.text' }, 
              { Wallet: <span>{intl.formatMessage({ id: 'Digital Wallet.Wallet'})}</span> }
            )}</h1>
          <h3>{ intl.formatMessage({ id: 'Multiple Tokens Support.' }) } { intl.formatMessage({ id: 'Fast & Secure.' }) }</h3>
          {/* <h3></h3> */}
        </li>
        <li>
          <div className='img-box'>
            <StaticImage
              placeholder="blurred"
              className={'stake-img'}
              alt="img"
              src="../static/images/abwallet/digit.png"
            />
          </div>
        </li>
      </ul>
    </div>
    </div>
  )
}